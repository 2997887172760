<template>
	<div class="f1 apps-page w-full dF fC f1 px-4">
		<div class="dF aC">
			<div class="dF aC f1">
				<h3>SSO Configurations</h3>
			</div>
		</div>
		<a-card :bodyStyle="{ padding: 0 }" class="my-3">
			<div>
				<a-table :rowKey="(e) => e.key" :columns="columns" :dataSource="SSOList" :loading="loading"
					:pagination="{ current: currentPage, total: totalSSOs, pageSize: pageSize }" @change="handleChange">
					<div slot="domains" slot-scope="sso">
						<span v-for="(s, index) in sso.domains" :key="index">
							<a-tag class="mb-2">{{ s }}</a-tag>
						</span>
					</div>
					<div slot="status" slot-scope="sso">
						<span class="button py-1 px-3"
							:style="sso.active ? 'background-color:#28C791' : 'background-color:#db5358'">
							{{ sso.active ? "ACTIVE" : "INACTIVE" }}</span>
					</div>
					<div slot="actions" slot-scope="sso" class="dF aC" style="gap: 20px">
						<div @click="editPartner(sso)" class="popoverContent">
							<a-icon type="edit" />
						</div>
						<div @click="deleteSSO(sso)" class="popoverContent">
							<a-icon type="delete" style="color:red" />
						</div>
					</div>
				</a-table>
			</div>
		</a-card>

		<a-modal v-model="ssoModal.visible" :title="ssoModal.title" :okText="ssoModal.okText" @cancel="closeModal"
			@ok="createOrUpdateSSO" :confirmLoading="confirmLoading" :width="900">
			<a-form-model ref="sso" :model="sso">
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item prop="name" label="Name" :rules="req('Please enter name')">
							<a-input v-model="sso.name" placeholder="Name" size="large">
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item prop="active" label="Active">
							<a-switch v-model="sso.active" checked-children="On" un-checked-children="Off" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item prop="federationMetadataUrl" label="Federation Metadata Url"
							:rules="req('Please enter Federation Metadata Url')">
							<a-textarea placeholder="Federation Metadata Url" size="large"
								v-model="sso.federationMetadataUrl" :rows="3" />
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="Domains" prop="domains" :rules="req('Please enter domains name')">
							<a-select mode="tags" size="large" placeholder="Please add domains" style="width: 100%"
								v-model="sso.domains">
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="Email Address Attribute" prop="emailAddressAttribute"
							:rules="req('Please enter Email Address Attribute')">
							<a-input v-model="sso.emailAddressAttribute" placeholder="Email Address Attribute"
								size="large">
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="First Name Attribute" prop="firstNameAttribute"
							:rules="req('Please enter First Name Attribute')">
							<a-input v-model="sso.firstNameAttribute" placeholder="First Name Attribute" size="large">
							</a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item label="Last Name Attribute" prop="lastNameAttribute"
							:rules="req('Please enter Last Name Attribute')">
							<a-input v-model="sso.lastNameAttribute" placeholder="Last Name Attribute" size="large">
							</a-input>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { mapMutations } from "vuex";
import _ from "lodash";

export default {
	data() {
		return {
			sortedInfo: {
				key: "createdAt",
				order: "DESC",
			},
			SSOList: [],
			loading: false,
			confirmLoading: false,
			currentPage: 1,
			totalSSOs: 0,
			pageSize: 10,
			selectedSSO: {},
			sso: {
				name: "",
				active: true,
				federationMetadataUrl: "",
				domains: [],
				emailAddressAttribute: "",
				firstNameAttribute: "",
				lastNameAttribute: ""
			},
			ssoModal: {
				visible: false,
				title: 'Add New SSO Configuration',
				okText: 'SUBMIT'
			},
			columns: [
				{
					title: "Name",
					key: "name",
					dataIndex: "name",
					sorter: true,
				},
				{
					title: "Domains",
					key: "domains",
					scopedSlots: { customRender: "domains" },
					sorter: true,
				},
				{
					title: "Status",
					key: "status",
					scopedSlots: { customRender: "status" },
					sorter: true,
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				}
			],
		};
	},
	computed: {
		createNewSSO() {
			return this.$store.state.addNew.sso;
		},

		allUsers() {
			return this.$store.state.allUsers;
		},

		search() {
			return this.$store.state.searchFilter.toLowerCase();
		},
	},
	watch: {
		search() {
			this.searchDebounce();
		},

		createNewSSO(value) {
			if (value) {
				this.ssoModal = {
					visible: true,
					title: 'Add New SSO Configuration',
					okText: 'SUBMIT'
				}
			}
		}
	},
	created() {
		this.getSSOsCount();
		this.getSSOs();
	},

	methods: {
		...mapMutations(["UPDATE_SEARCH", "UPDATE_ADD_NEW"]),

		req: (msg) => ({ required: true, message: msg }),

		searchDebounce: _.debounce(function () {
			this.getSSOsCount();
			this.getSSOs();
		}, 1000),

		async getSSOsCount() {
			try {
				let searchQuery = "";
				if (this.search) {
					searchQuery = `?_q=${this.search}`;
				}
				let { data } = await this.$api.get(
					`/sso-configurations/count${searchQuery}`
				);
				this.totalSSOs = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching sso count. Please try again!"))
				}
			}
		},

		async getSSOs() {
			this.loading = true;
			try {
				let start = (this.currentPage - 1) * this.pageSize;
				let searchQuery = "";
				if (this.search) {
					searchQuery = `&_q=${this.search}`;
				}
				let { data } = await this.$api.get(`/sso-configurations?_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`);
				this.SSOList = data.map((u, index) => {
					return {
						...u,
						key: index,
					};
				});
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching sso list. Please try again!"))
				}
			}
			this.loading = false;
		},

		handleChange(pagination, filter, sorter) {
			if (pagination) {
				this.currentPage = pagination.current;
			}
			if (sorter && sorter.column) {
				this.sortedInfo = {
					key: sorter.columnKey,
					order: sorter.order === "descend" ? "DESC" : "ASC",
				};
			}
			this.getSSOs();
		},

		createOrUpdateSSO() {
			this.$refs.sso.validate((valid) => {
				if (valid) {
					this.confirmLoading = true;
					let url = this.ssoModal.okText === 'UPDATE' ? `/sso-configurations/${this.sso.id}` : `/sso-configurations`
					this.$api[this.ssoModal.okText === 'UPDATE' ? 'put' : 'post'](url, { ...this.sso })
						.then(() => {
							this.confirmLoading = false;
							this.$message.success(this.ssoModal.okText === 'UPDATE' ? "Successfully Updated configured SSO" : "Successfully configured new SSO");
							this.UPDATE_ADD_NEW({
								key: "sso",
								value: false,
							});
							this.closeModal();
							this.getSSOsCount();
							this.getSSOs();
						})
						.catch((err) => {
							this.confirmLoading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, `Error while ${this.ssoModal.okText === 'UPDATE' ? "updating" : "configuring new"} SSO. Please try again!`));
							}
						});
				} else {
					return false;
				}
			});
		},

		async updateSSO() {
			this.$refs.sso.validate((valid) => {
				if (valid) {
					this.confirmLoading = true;
					this.$api
						.post(`/sso-configurations`, {
							...this.sso,
						})
						.then(() => {
							this.confirmLoading = false;

							this.$message.success(
								"Successfully configured new SSO"
							);
							this.UPDATE_ADD_NEW({
								key: "sso",
								value: false,
							});
							this.closeModal();
							this.getSSOsCount();
							this.getSSOs();
						})
						.catch((err) => {
							this.confirmLoading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while configuring new SSO. Please try again!"));
							}
						});
				} else {
					console.error("Invalid form details");
					return false;
				}
			});
		},

		editPartner(s) {
			this.sso = { ...s };
			this.ssoModal = {
				visible: true,
				title: 'Update SSO Configuration',
				okText: 'UPDATE'
			}
		},

		closeModal() {
			this.$refs.sso && this.$refs.sso.clearValidate();
			this.partner = {
				name: "",
				active: true,
				federationMetadataUrl: "",
				domains: [],
				emailAddressAttribute: "",
				firstNameAttribute: "",
				lastNameAttribute: ""
			}
			this.ssoModal = {
				visible: false,
				title: 'Add New SSO Configuration',
				okText: 'SUBMIT'
			}
		},

		async deleteSSO(sso) {
			let self = this;
			this.$confirm({
				title: `Delete Configured SSO`,
				content: (h) => <div>Are you sure you want to delete <strong> {sso.name} </strong> SSO?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.delete(`/sso-configurations/${sso.id}`).then(() => {
						self.loading = false;
						self.$message.success(`Successfully delete selected configured SSO.`);
						self.getSSOsCount();
						self.getSSOs();
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while deleting configured SSO. Please try again.`));
						}
					})
				}
			});
		},
	},

	beforeDestroy() {
		this.UPDATE_SEARCH("");
	},
};
</script>

<style lang="scss" scoped>
.button {
	color: #fff;
	border-radius: 4px;
	font-size: 14px;
	text-align: center;
	padding: 4px 10px;
	font-weight: 500;
}

.popoverContent {
	height: max-content;
	line-height: 30px;
	padding: 0 10px;
}

.popoverContent:hover {
	background-color: var(--off-white-light);
	color: var(--primary);
	cursor: pointer;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
	padding: 5px;
	background-color: white;
	border-radius: 20px;
}
</style>
